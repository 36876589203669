<template>
  <div
    class="table-container"
    ref="scrollContainer"
    @mousedown="startDrag"
    @mousemove="handleDrag"
    @mouseup="stopDrag"
    @mouseleave="stopDrag"
  >
    <div
      @click="duplicateCv = !duplicateCv"
      v-if="duplicateCv"
      class="w-100 bg-danger my-2 text-white ps-3 py-1 pointer"
    >
      Snimanje neuspešno. Vec postoji korisnik sa istim LN profilom!
    </div>
    <div v-if="!duplicateCv" class="my-2 py-1">&nbsp;</div>
    <p>Ukupan broj kandidata: {{ candidates?.length }}</p>
    <table class="">
      <tr class="bg-secondary text-white border border-secondary">
        <th
          @click="activateSearch('name')"
          :class="searchObject.name ? 'bg-primary' : 'bg-secondary'"
          class="text-center px-2 py-2 pointer"
        >
          <img class="icon" src="../assets/magnifying_glass.svg" />
          <p class="m-0 p-0 d-inline primljen-kandidat">
            Ime i prezime kandidata
          </p>
        </th>
        <th
          @click="activateSearch('position')"
          :class="searchObject.position ? 'bg-primary' : 'bg-secondary'"
          class="text-center px-2 py-2 border border-white border-top-0 border-bottom-0 pointer"
        >
          <img class="icon" src="../assets/magnifying_glass.svg" />
          <p class="m-0 p-0 d-inline primljen-kandidat">Naziv pozicije</p>
        </th>
        <th
          @click="activateSearch('state')"
          :class="searchObject.state ? 'bg-primary' : 'bg-secondary'"
          class="text-center px-2 py-2 border border-white border-top-0 border-left-0 border-bottom-0 pointer"
          style="width: 10px;"
        >
          <img class="icon" src="../assets/magnifying_glass.svg" />
          <p class="m-0 p-0 d-inline primljen-kandidat">Država</p>
        </th>
        <th
          @click="activateSearch('city')"
          :class="searchObject.city ? 'bg-primary' : 'bg-secondary'"
          class="text-center px-2 py-2 border border-white border-top-0 border-left-0 border-bottom-0 pointer"
        >
          <img class="icon" src="../assets/magnifying_glass.svg" />
          <p class="m-0 p-0 d-inline primljen-kandidat">Grad</p>
        </th>
        <th
          @click="activateSearch('education')"
          :class="searchObject.education ? 'bg-primary' : 'bg-secondary'"
          class="text-center px-2 py-2 border border-white border-top-0 border-left-0 border-bottom-0 pointer"
        >
          <img class="icon" src="../assets/magnifying_glass.svg" />
          <p class=" primljen-kandidat">
            SSS i <br />
            obrazovanje
          </p>
        </th>
        <th
          class="text-center px-2 py-2 border border-white border-top-0 border-left-0 border-bottom-0 xsm"
        >
          <p class="m-0 p-0 d-inline primljen-kandidat">Email</p>
        </th>
        <th
          class="text-center px-2 py-2 border border-white border-top-0 border-left-0 border-bottom-0 xsm"
        >
          <p class="m-0 p-0 d-inline primljen-kandidat">LN</p>
        </th>
        <th
          class="text-center px-1 py-2 border border-white border-top-0 border-left-0 border-bottom-0"
        >
          <p class=" primljen-kandidat">Primljen kandidat</p>
        </th>
        <th
          class="text-center px-1 py-2 border border-white border-top-0 border-left-0 border-bottom-0"
        >
          <p class=" primljen-kandidat">Neadekva<br />tan kandidat</p>
        </th>
        <th class="text-center px-2 py-2">
          <p class="m-0 p-0 d-inline primljen-kandidat">Akcija</p>
        </th>
      </tr>
      <tr class="">
        <td class=" text-center border border-secondary px-2 sm">
          <input class="md" v-model="candidateTemplate.name" />
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input class="md" v-model="candidateTemplate.position" />
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input class="sm" v-model="candidateTemplate.state" />
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input class="sm" v-model="candidateTemplate.city" />
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input class="md" v-model="candidateTemplate.education" />
        </td>
        <td class="text-center border border-secondary px-2 xsm">
          <input class="xsm" v-model="candidateTemplate.email" />
        </td>
        <td class="text-center border border-secondary px-2 xsm">
          <input class="xsm" v-model="candidateTemplate.cv" />
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input type="checkbox" v-model="candidateTemplate.hired" />
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input type="checkbox" v-model="candidateTemplate.inadequate" />
        </td>
        <td class="border border-secondary border-left-0 px-2">
          <button
            @click="submit(candidateTemplate)"
            class="btn btn-sm btn-success mx-2 my-1 "
          >
            Dodaj
          </button>
          <button
            @click="search()"
            class="btn btn-sm btn-primary  mx-2 my-1 px-3"
          >
            <img class="icon" src="../assets/magnifying_glass.svg" />
          </button>
        </td>
      </tr>
      <tr v-for="(item, i) in candidates" :key="i" class="">
        <td class="text-center border border-secondary px-2 sm">
          <input v-if="item.change" class="md" v-model="item.name" />
          <p v-if="!item.change" class="m-0 p-0">{{ item.name }}</p>
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input v-if="item.change" class="md" v-model="item.position" />
          <p v-if="!item.change" class="m-0 p-0">{{ item.position }}</p>
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input v-if="item.change" class="sm" v-model="item.state" />
          <p v-if="!item.change" class="m-0 p-0">{{ item.state }}</p>
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input v-if="item.change" class="sm" v-model="item.city" />
          <p v-if="!item.change" class="m-0 p-0">{{ item.city }}</p>
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input v-if="item.change" class="md" v-model="item.education" />
          <p v-if="!item.change" class="m-0 p-0">{{ item.education }}</p>
        </td>
        <td class="text-center border border-secondary px-2 xsm">
          <img
            v-if="item.email && !item.change"
            @click="copyToClipboard(item.email)"
            class="icon-blue"
            src="../assets/envelope.svg"
          /><input v-if="item.change" class="xsm" v-model="item.email" />
        </td>
        <td class="text-center border border-secondary px-2 xsm">
          <img
            v-if="item.cv && !item.change"
            @click="openNewPage(item.cv)"
            class="icon-ln"
            src="../assets/linkedin.svg"
          /><input v-if="item.change" class="xsm" v-model="item.cv" />
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input type="checkbox" v-model="item.hired" />
        </td>
        <td class="text-center border border-secondary px-2 sm">
          <input type="checkbox" v-model="item.inadequate" />
        </td>
        <td class="border border-secondary px-2 border-bottom">
          <button
            @click="deleteOneCandidate(item._id)"
            class="btn btn-sm btn-danger  mx-2 my-1"
          >
            Obriši
          </button>
          <button
            v-if="!item.change"
            @click="item.change = true"
            class="btn btn-sm btn-warning mx-2 my-1"
          >
            Izmeni
          </button>
          <button
            v-if="item.change"
            @click="change(item)"
            class="btn btn-sm btn-success mx-2 my-1"
          >
            Snimi
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: mapGetters(["candidates"]),
  data() {
    return {
      candidateTemplate: {
        name: null,
        position: null,
        state: null,
        city: null,
        education: null,
        cv: null,
        email: null,
        hired: null,
        inadequate: null,
      },
      candidateNull: {
        name: null,
        position: null,
        state: null,
        city: null,
        education: null,
        cv: null,
        email: null,
        hired: null,
        inadequate: null,
      },
      allCandidates: [],
      searchObject: {
        name: false,
        position: false,
        state: false,
        town: false,
        education: false,
      },
      duplicateCv: false,
      isDragging: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,
    };
  },
  methods: {
    ...mapActions([
      "getAllCandidates",
      "submitCandidates",
      "deleteCandidate",
      "changeCandidate",
    ]),

    async submit(candidate) {
      this.duplicateCv = false;
      for (let i = 0; i < this.candidates?.length; i++) {
        if (this.candidates[i].cv === candidate.cv) {
          this.duplicateCv = true;
        }
        console.log(this.duplicateCv, candidate.cv, this.candidates?.length);
      }
      if (!this.duplicateCv) {
        await this.submitCandidates(candidate);
        await this.getAllCandidates();
        this.candidateTemplate = this.candidateNull;
      }
    },
    async change(candidate) {
      this.duplicateCv = false;
      for (let i = 0; i < this.candidates?.length; i++) {
        if (this.candidates[i].cv === candidate.cv) {
          this.duplicateCv = true;
        }
        console.log(this.duplicateCv, candidate.cv, this.candidates?.length);
      }
      if (!this.duplicateCv) {
        await this.submitCandidates(candidate);
        await this.getAllCandidates();
      }
    },
    async deleteOneCandidate(id) {
      await this.deleteCandidate(id);
      await this.getAllCandidates();
    },
    activateSearch(item) {
      if (this.searchObject[item]) {
        this.searchObject[item] = false;
      } else {
        for (const key in this.searchObject) {
          this.searchObject[key] = false;
        }
        this.searchObject[item] = true;
      }
      this.candidateTemplate = this.candidateNull;
    },
    async search() {
      let data = {
        searchString: null,
        searchField: null,
      };

      for (const key in this.searchObject) {
        if (this.searchObject[key]) {
          data.searchField = key;
          data.searchString = this.candidateTemplate[key];
        }
      }

      data.searchString && data.searchField
        ? await this.getAllCandidates(data)
        : await this.getAllCandidates();

      data = {};
    },
    async copyToClipboard(text) {
      try {
        /* await clipboard.writeText(text); */
        const textarea = document.createElement("textarea");
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
    },
    openNewPage(link) {
      window.open(link, "_blank");
    },
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
      this.scrollLeft = this.$refs.scrollContainer.scrollLeft;
      this.scrollTop = this.$refs.scrollContainer.scrollTop;
    },
    handleDrag(event) {
      if (!this.isDragging) return;

      const deltaX = event.clientX - this.startX;
      const deltaY = event.clientY - this.startY;

      this.$refs.scrollContainer.scrollLeft = this.scrollLeft - deltaX;
      this.$refs.scrollContainer.scrollTop = this.scrollTop - deltaY;
    },
    stopDrag() {
      this.isDragging = false;
    },
  },
  async created() {
    await this.getAllCandidates();
    this.allCandidates = this.candidates;
    this.allCandidates.forEach((element) => {
      element.change = false;
    });
  },
};
</script>
<style scoped>
.table-container {
  position: relative;
  padding-bottom: 10px;
  scrollbar-width: thin;
}

@media (max-width: 1400px) {
  .table-container {
    overflow-x: scroll;
  }

  .table-container::-webkit-scrollbar {
    width: 12px;
  }

  .table-container::-webkit-scrollbar-track {
    background: #6c757d;
  }

  .table-container::-webkit-scrollbar-thumb {
    background: #0dcaf0;
  }

  .table-container::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: #0d6efd;
  }
}

.xsm {
  width: 50px;
}

.sm {
  width: 100px;
}

.pointer {
  cursor: pointer;
}

.icon {
  width: 15px;
  filter: brightness(0) invert(1) sepia(1) hue-rotate(180deg);
}

.icon-blue {
  width: 25px;
  cursor: pointer;
  filter: invert(25%) sepia(100%) saturate(750%) hue-rotate(220deg);
}

.icon-blue:hover {
  transition: 0.3s;
  transform: scale(1.3);
}

.icon-ln {
  width: 25px;
  cursor: pointer;
}

.icon-ln:hover {
  transition: 0.3s;
  transform: scale(1.1);
}

.md {
  width: 150px;
}
.primljen-kandidat {
  font-size: 10px !important;
  margin: 0;
}
th:nth-child(1),
td:nth-child(1) {
  max-width: 120px;
  text-wrap: wrap;

  input {
    width: 100px;
  }
}
th:nth-child(2),
td:nth-child(2) {
  max-width: 150px;
  overflow-wrap: break-word;

  input {
    width: 130px;
  }
}
th:nth-child(3),
td:nth-child(3) {
  max-width: 100px;
  overflow-wrap: break-word;

  input {
    width: 80px;
  }
}
th:nth-child(4),
td:nth-child(4) {
  max-width: 100px;
  overflow-wrap: break-word;

  input {
    width: 80px;
  }
}
th:nth-child(5),
td:nth-child(5) {
  max-width: 80px;
  overflow-wrap: break-word;

  input {
    width: 60px;
  }
}
th:nth-child(8),
td:nth-child(8) {
  max-width: 60px;
}
th:nth-child(9),
td:nth-child(9) {
  max-width: 60px;
}
th:nth-child(10),
td:nth-child(10) {
  max-width: 80px;
}
</style>
